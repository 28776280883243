import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';

import { ApiResponse } from '@utils/models/ApiResponse';
import { MessageUtilService } from '@utils/services/message-util.service';

import { ProcessCopyOrCloneCustomDto } from '~models/carga/dto/ProcessCopyOrCloneCustomDto';
import { ScaRoTotales } from '~models/carga/Util/ScaRoTotales';
import { ScaRoutingDto } from '~models/carga/interface/ScaRouting';
import { ScaRoutingDetalleProjection } from '~models/carga/projection/ScaRoutingDetalleProjection';
import { ScaRoutingFinancieroProjection } from '~models/carga/projection/ScaRoutingFinancieroProjection';
import { ScaRoutingProjection } from '~models/carga/projection/ScaRoutingProjection';
import { ScaRoutingPorcentajeUpdateCustomDto } from '~models/carga/dto/ScaRoutingPorcentajeUpdateCustomDto';
import { ScaRoValidators } from '~models/carga/Util/ScaRoValidators';
import { SearchScaRoutingDto } from '~models/carga/dto/SearchScaRoutingDto';
import { ScaRoutingIdsDataDto } from '~models/carga/dto/ScaRoutingIdsDataDto';
import { ScaRoutingConsolidadoListado } from '~models/carga/interface';
import { ScaOrdenManifiestoDtoSearch } from '~models/carga/search/ScaOrdenManifiestoDtoSearch';
import { SearchParameter } from 'app/modules/gestion/common/components/filtro-por-referencia/filtro-por-referencia.service';
import { ScaRoutingTituloDto } from '~models/carga/dto/ScaRoutingTituloDto';
import { TbAgenteExtranjero } from '~models/maestros/interface';

@Injectable({
	providedIn: 'root',
})
export class ScaRoutingService {
	private readonly _url = `${environment.HOST_CARGA}/scaroutinges`;

	scaRouting: BehaviorSubject<ScaRoutingDto> = new BehaviorSubject<ScaRoutingDto>(<ScaRoutingDto>{});

	scaRoTotales: BehaviorSubject<ScaRoTotales> = new BehaviorSubject<ScaRoTotales>(new ScaRoTotales());

	infoGeneralValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	infoPrincipalValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	constructor(private http: HttpClient, private messageUtilService: MessageUtilService) {}

	findById(id: number): Observable<ScaRoutingDto> {
		return this.http.get<ApiResponse<ScaRoutingDto>>(`${this._url}/model-se-ro/${id}`).pipe(map((res) => res.data));
	}

	generateJsonFindById(id: number): Observable<any> {
		return this.http.get<ApiResponse<any>>(`${this._url}/model-se-ro/generateJsonFindById/${id}`).pipe(map((res) => res.data));
	}

	findForEventoByNroOrden(nroRouting: string): Observable<ScaRoutingDto> {
		return this.http.get<ApiResponse<ScaRoutingDto>>(`${this._url}/model-se-ro/findForEventoByNroOrden/${nroRouting}`).pipe(map((res) => res.data));
	}

	findDetalleByIdRouting(idRouting: number): Observable<ScaRoutingDetalleProjection> {
		return this.http.get<ApiResponse<ScaRoutingDetalleProjection>>(`${this._url}/model-se-ro/findDetalleByIdRouting/${idRouting}`).pipe(map((res) => res.data));
	}

	getTituloByIdRouting(idRouting: number): Observable<ScaRoutingTituloDto> {
		return this.http.get<ApiResponse<ScaRoutingTituloDto>>(`${this._url}/getTituloByIdRouting/${idRouting}`).pipe(map((res) => res.data));
	}

	getTbAgenteExtranjeroByIdRouting(idRouting: number): Observable<TbAgenteExtranjero> {
		return this.http.get<ApiResponse<TbAgenteExtranjero>>(`${this._url}/getTbAgenteExtranjeroByIdRouting/${idRouting}`).pipe(map((res) => res.data));
	}

	findAllCustomDto(searchScaRoutingDto: SearchScaRoutingDto | SearchParameter): Observable<ScaRoutingProjection[]> {
		return this.http.post<ApiResponse<ScaRoutingProjection[]>>(`${this._url}/model-se-ro/findAllCustomDto`, searchScaRoutingDto).pipe(map((res) => res.data));
	}

	insert(scaRouting: ScaRoutingDto): Observable<[boolean, ScaRoutingDto]> {
		return this.http.post<ApiResponse<ScaRoutingDto>>(`${this._url}`, scaRouting).pipe(map(({ error, data }) => [error, data]));
	}

	update(scaRouting: ScaRoutingDto): Observable<[boolean, ScaRoutingDto]> {
		return this.http.put<ApiResponse<ScaRoutingDto>>(`${this._url}`, scaRouting).pipe(map(({ error, data }) => [error, data]));
	}

	delete(scaRouting: Partial<ScaRoutingDto>): Observable<ApiResponse<void>> {
		return this.http.delete<ApiResponse<void>>(`${this._url}`, { body: scaRouting });
	}

	copy(dto: ProcessCopyOrCloneCustomDto): Observable<ScaRoutingDto> {
		return this.http.post<ApiResponse<ScaRoutingDto>>(`${this._url}/copy`, dto).pipe(map((res) => res.data));
	}

	findAllCustomDtoByIdOrdenManifiesto(id: number): Observable<ScaRoutingConsolidadoListado[]> {
		return this.http.get<ApiResponse<ScaRoutingConsolidadoListado[]>>(`${this._url}/model-se-ro/findAllCustomDtoByIdOrdenManifiesto/${id}`).pipe(map((res) => res.data));
	}

	// findAllCustomDtoByScaOrdenManifiestoDtoSearch(scaRoutingFreeDtoSearch: ScaRoutingFreeDtoSearch): Observable<ScaRoutingConsolidadoListado[]> {
	// 	return this.http.post<ApiResponse<ScaRoutingConsolidadoListado[]>>(`${this._url}/findAllCustomDtoByScaOrdenManifiestoDtoSearch`, scaRoutingFreeDtoSearch).pipe(map((res) => res.data));
	// }

	validRo(): boolean {
		const validRo = new ScaRoValidators();
		validRo.message = 'Ingrese los datos requeridos';

		this.infoGeneralValid.pipe().subscribe((valid: boolean) => {
			validRo.stepTitulo = 'Información General';
			validRo.valid = valid;
		});

		if (!validRo.valid) {
			this.messageUtilService.error(`${validRo.stepTitulo}: ${validRo.message}`);
			return false;
		}

		this.infoPrincipalValid.pipe().subscribe((valid: boolean) => {
			validRo.stepTitulo = 'Información Principal';
			validRo.valid = valid;
		});

		if (!validRo.valid) {
			this.messageUtilService.error(`${validRo.stepTitulo}: ${validRo.message}`);
			return false;
		}

		return validRo.valid;
	}

	findAllDtoCustomForFinanciera(searchScaRoutingDto: SearchParameter | SearchScaRoutingDto): Observable<ScaRoutingFinancieroProjection[]> {
		return this.http.post<ApiResponse<ScaRoutingFinancieroProjection[]>>(`${this._url}/model-se-ro/findAllDtoCustomForFinanciera`, searchScaRoutingDto).pipe(map((res) => res.data));
	}

	updateCustomOfPorcentajeForIdRouting(update: ScaRoutingPorcentajeUpdateCustomDto): Observable<number> {
		return this.http.post<ApiResponse<number>>(`${this._url}/updateCustomOfPorcentajeForIdRouting`, update).pipe(map((res) => res.data));
	}

	findIdRoutingByNroRouting(nroRouting: string): Observable<number> {
		return this.http.get<ApiResponse<number>>(`${this._url}/model-se-ro/findIdRoutingByNroRouting/${nroRouting}`).pipe(map((res) => res.data));
	}

	findIdOrdenCargaByNroRouting(nroRouting: string): Observable<number> {
		return this.http.get<ApiResponse<number>>(`${this._url}/model-se-ro/findIdOrdenCargaByNroRouting/${nroRouting}`).pipe(map((res) => res.data));
	}

	insertCatalogoOperativo(idRouting: number): Observable<boolean> {
		return this.http.get<ApiResponse<boolean>>(`${this._url}/insertCatalogoOperativo/${idRouting}`).pipe(map((res) => res.data));
	}

	findIdsForDataByNroRouting(nroRouting: string): Observable<ScaRoutingIdsDataDto> {
		return this.http.get<ApiResponse<ScaRoutingIdsDataDto>>(`${this._url}/model-se-ro/findIdsForDataByNroRouting/${nroRouting}`).pipe(map((res) => res.data));
	}

	findAllTbCodificacionSunatPage(search: any, page: number, size: number): Observable<ScaRoutingConsolidadoListado> {
		return this.http
			.post<ApiResponse<ScaRoutingConsolidadoListado>>(`${this._url}/model-se-ro/findAllCustomDtoByScaOrdenManifiestoDtoSearch?page=${page}&size=${size}`, search)
			.pipe(map((res) => res.data));
	}

	findAllBySearch(search: ScaOrdenManifiestoDtoSearch): Observable<ScaRoutingConsolidadoListado[]> {
		return this.http.post<ApiResponse<ScaRoutingConsolidadoListado[]>>(`${this._url}/model-se-ro/findAllBySearch`, search).pipe(map((res) => res.data));
	}

	countByIdRoutingAndCodUnidadMedida(idRouting: number, codUnidadMedida: string): Observable<number> {
		return this.http.get<ApiResponse<number>>(`${this._url}/model-se-ro/countByIdRoutingAndCodUnidadMedida/${idRouting}/${codUnidadMedida}`).pipe(map((resp) => resp.data));
	}

	executeAfterSave(idRouting: number, boolNewReg: boolean): Observable<string[]> {
		return this.http.get<ApiResponse<string[]>>(`${this._url}/executeAfterSave/${idRouting}/${boolNewReg}`).pipe(map((res) => res.data));
	}
}
